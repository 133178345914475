import styled from 'styled-components'

import { ReactComponent as DiscordI } from './images/discord.svg'
import { ReactComponent as GithubI } from './images/github.svg'
import { ReactComponent as TwitterI } from './images/twitter-safe.svg'
import { ReactComponent as TelegramgroupI } from './images/Telegramgroup.svg'
import { ReactComponent as TelegramChannel} from './images/TelegramChannel.svg'
import { ReactComponent as InstagramI } from './images/InstagramI.svg'
import { ReactComponent as FacebookI } from './images/FacebookI.svg'
import { ReactComponent as YoutubeI } from './images/YoutubeI.svg'





export const DiscordIcon = styled(DiscordI)<{ size?: number; fill?: string }>`
  height: ${({ size }) => (size ? size + 'px' : '32px')};
  width: ${({ size }) => (size ? size + 'px' : '32px')};
  fill: ${({ fill }) => fill ?? '#e8ca7f'};
  opacity: 1;
`

export const TwitterIcon = styled(TwitterI)<{ size?: number; fill?: string }>`
  height: ${({ size }) => (size ? size + 'px' : '32px')};
  width: ${({ size }) => (size ? size + 'px' : '32px')};
  fill: ${({ fill }) => fill ?? '#e8ca7f'};
  opacity: 1;
`

// export const TelegramChannel = styled(TelegramChannel)<{ size?: number; fill?: string }>`
//   height: ${({ size }) => (size ? size + 'px' : '32px')};
//   width: ${({ size }) => (size ? size + 'px' : '32px')};
//   fill: ${({ fill }) => fill ?? '#e8ca7f'};
//   opacity: 1;
// `
export const Telegram = styled(TelegramChannel)<{ size?: number; fill?: string }>`
height: ${({ size }) => (size ? size + 'px' : '48px')};
width: ${({ size }) => (size ? size + 'px' : '48px')};
fill: ${({ fill }) => fill ?? '#e8ca7f'};
opacity: 1;
`
export const Instagram = styled(InstagramI)<{ size?: number; fill?: string }>`
height: ${({ size }) => (size ? size + 'px' : '48px')};
width: ${({ size }) => (size ? size + 'px' : '48px')};
fill: ${({ fill }) => fill ?? '#e8ca7f'};
opacity: 1;
`
export const Facebook = styled(FacebookI)<{ size?: number; fill?: string }>`
height: ${({ size }) => (size ? size + 'px' : '42px')};
width: ${({ size }) => (size ? size + 'px' : '42px')};
fill: ${({ fill }) => fill ?? '#e8ca7f'};
opacity: 1;
`
export const Youtube = styled(YoutubeI)<{ size?: number; fill?: string }>`
height: ${({ size }) => (size ? size + 'px' : '48px')};
width: ${({ size }) => (size ? size + 'px' : '48px')};
fill: ${({ fill }) => fill ?? '#e8ca7f'};
opacity: 1;
`

export const GithubIcon = styled(GithubI)<{ size?: number; fill?: string }>`
height: ${({ size }) => (size ? size + 'px' : '38px')};
width: ${({ size }) => (size ? size + 'px' : '38px')};
fill: ${({ fill }) => fill ?? '#e8ca7f'};
opacity: 1;
`
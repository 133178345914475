import '../../../src/styles/globle.css'

import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { useAccountDrawer } from 'components/AccountDrawer'
import Web3Status from 'components/Web3Status'
import { chainIdToBackendName } from 'graphql/data/util'
import { useIsNftPage } from 'hooks/useIsNftPage'
import { useIsPoolsPage } from 'hooks/useIsPoolsPage'
import { Box } from 'nft/components/Box'
import { Row } from 'nft/components/Flex'
// import { UniIcon } from 'nft/components/icons'
import { ReactNode, useCallback } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { NavLink, NavLinkProps, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import App_Icon from '../../assets/images/Logo.png'
import option from '../../assets/images/options.svg'
import Blur from './Blur'
import { ChainSelector } from './ChainSelector'
import * as styles from './style.css'
const Nav = styled.nav`
  padding: ${({ theme }) => `${theme.navVerticalPad}px 12px`};
  width: 100%;
  z-index: 2;
`

interface MenuItemProps {
  href: string
  id?: NavLinkProps['id']
  isActive?: boolean
  children: ReactNode
  dataTestId?: string
}

const MenuItem = ({ href, dataTestId, id, isActive, children }: MenuItemProps) => {
  return (
    <NavLink
      to={href}
      className={isActive ? styles.activeMenuItem : styles.menuItem}
      id={id}
      style={{ textDecoration: 'none' }}
      data-testid={dataTestId}
    >
      {children}
    </NavLink>
  )
}

export const PageTabs = () => {
  const { pathname } = useLocation()
  const { chainId: connectedChainId } = useWeb3React()
  const chainName = chainIdToBackendName(connectedChainId)

  const isPoolActive = useIsPoolsPage()
  const isNftPage = useIsNftPage()

  // const shouldDisableNFTRoutes = useDisableNFTRoutes()
  // const infoExplorePageEnabled = useInfoExplorePageEnabled()

  return (
    <>
      {/* <span className="swap_newbtn">
      
      </span> */}
      <MenuItem href="/swap">
        <Trans>Swap</Trans>
      </MenuItem>
      <MenuItem href="/pools" dataTestId="pool-nav-link" isActive={isPoolActive}>
        <Trans>Pools</Trans>
      </MenuItem>
      <MenuItem href={`/tokens/${chainName.toLowerCase()}`} isActive={pathname.startsWith('/tokens')}>
        <Trans>Tokens</Trans>
      </MenuItem>
      <MenuItem href={`/staking/${chainName.toLowerCase()}`} isActive={pathname.startsWith('/staking')}>
        <Trans>Staking</Trans>
      </MenuItem>
      <MenuItem dataTestId="nft-nav" href="/nfts" isActive={isNftPage}>
        <Trans>NFTs</Trans>
      </MenuItem>
    </>
  )
}

const Navbar = ({ blur }: { blur: boolean }) => {
  const isNftPage = useIsNftPage()
  // const sellPageState = useProfilePageState((state) => state.state)
  const navigate = useNavigate()
  // const isNavSearchInputVisible = useIsNavSearchInputVisible()

  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer()

  const handleUniIconClick = useCallback(() => {
    if (accountDrawerOpen) {
      toggleAccountDrawer()
    }
    navigate({
      pathname: '/',
      search: '?intro=true',
    })
  }, [accountDrawerOpen, navigate, toggleAccountDrawer])

  return (
    <>
      {blur && <Blur />}
      <Nav className="site-header border_gray">
        <div className="container ">
          <Box className="header" display="flex" height="full" flexWrap="nowrap">
            <Box className={styles.leftSideContainer}>
              <Box className={styles.logoContainer}>
                <img src={App_Icon} alt="logo" height={48} className={styles.logo} onClick={handleUniIconClick} />
              </Box>
              {!isNftPage && (
                <Box display={{ sm: 'flex', lg: 'none' }}>
                  <ChainSelector leftAlign={true} />
                </Box>
              )}
              <Row display={{ sm: 'none', lg: 'flex' }}>
                <PageTabs />
              </Row>
            </Box>

            <Box className={styles.rightSideContainer}>
              <Row gap="12">
                <Web3Status />
              </Row>
            </Box>
            <div className=" ms-4">
              <Dropdown className="btn-drop">
                <Dropdown.Toggle variant="" id="dropdown-basic" className="border">
                  <img src={option} alt="" width={55} className="drop-down-btn text-center" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="bg-color p-3">
                  <Dropdown.Item href="" onClick={() => navigate('/summary#/summary')} className="text-warning  ">
                    Summary
                  </Dropdown.Item>
                  <Dropdown.Item href="" onClick={() => navigate('/contact-us')} className="text-warning ">
                    Help Center
                  </Dropdown.Item>
                  <Dropdown.Item href="" onClick={() => navigate('/referral#/referral')} className="text-warning">
                    My Referral Code
                  </Dropdown.Item>
                  <Dropdown.Item href="" onClick={() => navigate('/teams#/teams')} className="text-warning">
                    Generation
                  </Dropdown.Item>    
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Box>
        </div>
      </Nav>
    </>
  )
}

export default Navbar

import '../../../src/styles/globle.css'

import { Container } from 'react-bootstrap'

const Index = () => {
  return (
    <Container style={{ marginTop: '2rem' }}>
      <div className="mx-auto">Withdraw</div>
    </Container>
  )
}

export default Index

/* eslint-disable @typescript-eslint/no-unused-vars */
import { BrowserEvent, InterfaceElementName, SharedEventName } from '@uniswap/analytics-events'
import { TraceEvent } from 'analytics'
import { useDisableNFTRoutes } from 'hooks/useDisableNFTRoutes'
import { FaTelegramPlane } from 'react-icons/fa'
import { FaDiscord, FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { BREAKPOINTS } from 'theme'
import { ExternalLink, StyledRouterLink } from 'theme/components'
import { useIsDarkMode } from 'theme/components/ThemeToggle'

import FooterLogo from '../../assets/images/footer-logo.png'
import { GithubIcon } from './Icons'
import darkUnicornImgSrc from './images/unicornEmbossDark.png'
import lightUnicornImgSrc from './images/unicornEmbossLight.png'

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 48px;
  max-width: 1440px;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
`

const LogoSectionLeft = styled(LogoSection)`
  display: none;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    display: flex;
  }
`

const LogoSectionBottom = styled(LogoSection)`
  display: flex;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`

const StyledLogo = styled.img`
  width: 72px;
  height: 72px;
  display: none;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    display: block;
  }
`

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0 0 0;
`

const SocialLink = styled.a`
  display: flex;
`

const FooterLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  @media screen and (min-width: ${BREAKPOINTS.xl}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;
  }
`

const LinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 20px 0 0 0;
  @media screen and (min-width: ${BREAKPOINTS.xl}px) {
    margin: 0;
  }
`

const LinkGroupTitle = styled.span`
  font-size: 16px;
  line-height: 20px;
  font-weight: 535;
`

const ExternalTextLink = styled(ExternalLink)`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.neutral2};
`

const TextLink = styled(StyledRouterLink)`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.neutral2};
`

const Copyright = styled.span`
  font-size: 14px;
  line-height: 20px;
  margin: 1rem 0 0 0;
  color: ${({ theme }) => theme.neutral3};
`

const LogoSectionContent = () => {
  const isDarkMode = useIsDarkMode()
  return (
    <>
      <StyledLogo src={isDarkMode ? darkUnicornImgSrc : lightUnicornImgSrc} alt="Uniswap Logo" />
      <SocialLinks>
        <SocialLink href="https://discordapp.com/users/1115012989423210536" target="_blank" rel="noopener noreferrer">
          <FaDiscord />
        </SocialLink>
        <TraceEvent
          events={[BrowserEvent.onClick]}
          name={SharedEventName.ELEMENT_CLICKED}
          element={InterfaceElementName.TWITTER_LINK}
        >
          <SocialLink href="https://twitter.com/DEFICLUB2" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </SocialLink>
        </TraceEvent>
        <SocialLink href="https://t.me/deficlubcommunity" target="_blank" rel="noopener noreferrer">
          <GithubIcon size={38} />
        </SocialLink>

        {/* <SocialLink
          href="https://t.me/deficlubcommunity"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Telegram size={48} />
        </SocialLink> */}

        <SocialLink href="https://t.me/deficlubcommunity" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </SocialLink>

        <SocialLink href="https://t.me/deficlubcommunity" target="_blank" rel="noopener noreferrer">
          <FaYoutube />
        </SocialLink>

        <SocialLink href="https://t.me/deficlubcommunity" target="_blank" rel="noopener noreferrer">
          <FaFacebookF />
        </SocialLink>
      </SocialLinks>
      {/* <Copyright>© {new Date().getFullYear()} Uniswap Labs</Copyright> */}
    </>
  )
}

export const AboutFooter = () => {
  const navigate = useNavigate()
  const scrollToFAQ = async () => {
    navigate('/')
    const faqSection = document.getElementById('faq')
  }
  const shouldDisableNFTRoutes = useDisableNFTRoutes()
  return (
    <Footer className="bg-black">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-lg-5 mb-4 text-center text-md-start">
            <ExternalTextLink href="/" rel="noopener noreferrer">
              <img className="f-logo" src={FooterLogo} alt="" width={327} />
            </ExternalTextLink>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-8">
                <p className="f-14">
                  Defi Club is a Decentralized Exchange, Powered by Cutting-Edge Smart Contracts, Revolutionizes Crypto
                  Trading, Staking, Pooling And Bridging For A Seamless, Secure Experience
                </p>

                <SocialLinks className="links">
                  <SocialLink
                    href="https://discordapp.com/users/1115012989423210536"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaDiscord />
                  </SocialLink>
                  <SocialLink href="https://twitter.com/DEFICLUB2" target="_blank" rel="noopener noreferrer">
                    <FaTwitter />
                  </SocialLink>

                  {/* <SocialLink
                    href="https://t.me/deficlub_official"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Telegram size={42} />
                  </SocialLink> */}
                  <SocialLink
                    href="https://www.youtube.com/channel/UC4GJK74oe7Q8wUuoRfwHSFw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaYoutube />
                  </SocialLink>
                  <SocialLink
                    href="https://www.instagram.com/deficlub_official/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram />
                  </SocialLink>
                  <SocialLink
                    href="https://www.facebook.com/profile.php?id=100084635923285&mibextid=ZbWKwL"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebookF />
                  </SocialLink>

                  <SocialLink href="https://t.me/deficlubcommunity" target="_blank" rel="noopener noreferrer">
                    <FaTelegramPlane />
                  </SocialLink>
                </SocialLinks>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-md-4">
                <LinkGroup>
                  <LinkGroupTitle className="f-20 text-theme">Defi Club</LinkGroupTitle>
                  <ExternalTextLink href="/">About Defi Club</ExternalTextLink>
                  <ExternalTextLink href="/">Media Kit</ExternalTextLink>
                  <ExternalTextLink href="/">Announcements</ExternalTextLink>
                </LinkGroup>
              </div>
              <div className="col-md-4">
                <LinkGroup>
                  <LinkGroupTitle className="f-20 text-theme">Services</LinkGroupTitle>
                  <a className="text-decoration-none" href="https://swap.deficlub.exchange/">
                    Swap
                  </a>
                  <TextLink to="/tokens">Tokens</TextLink>
                  {!shouldDisableNFTRoutes && <TextLink to="/nfts">NFTs</TextLink>}
                  <TextLink to="/pools">Pools</TextLink>
                </LinkGroup>
              </div>
              <div className="col-md-4">
                <LinkGroup>
                  <LinkGroupTitle className="f-20 text-theme">Information</LinkGroupTitle>
                  <TraceEvent
                    events={[BrowserEvent.onClick]}
                    name={SharedEventName.ELEMENT_CLICKED}
                    element={InterfaceElementName.SUPPORT_LINK}
                  >
                    <TextLink to="/terms-condition">Terms & Conditions</TextLink>
                    <TextLink to="/privacy-policy">Privacy Policy</TextLink>
                    {/* @ts-ignore */}
                    {/* <ExternalTextLink onClick={scrollToFAQ}>FAQs</ExternalTextLink> */}
                    <TextLink to="/faq">FAQs</TextLink>

                    <TextLink to="/contact-us">Help Center </TextLink>
                  </TraceEvent>
                  <TraceEvent
                    events={[BrowserEvent.onClick]}
                    name={SharedEventName.ELEMENT_CLICKED}
                    element={InterfaceElementName.SUPPORT_LINK}
                  ></TraceEvent>
                </LinkGroup>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <div className="site-copyright text-white">
              <p>
                © Copyright 2024 <span className="text-theme">Defi Club</span> | All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </Footer>
  )
}

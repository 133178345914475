import { BrowserEvent, InterfaceElementName, InterfaceEventName } from '@uniswap/analytics-events'
import { useWeb3React } from '@web3-react/core'
import { TraceEvent } from 'analytics'
import axios from 'axios'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import Loader from 'components/Icons/LoadingSpinner'
import { ActivationStatus, useActivationState } from 'connection/activate'
import { Connection } from 'connection/types'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { ethers } from 'ethers'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { useIsDarkMode } from 'theme/components/ThemeToggle'
import { flexColumnNoWrap, flexRowNoWrap } from 'theme/styles'
import { switchNetwork } from 'utils/eth'

const OptionCardLeft = styled.div`
  ${flexColumnNoWrap};
  flex-direction: row;
  align-items: center;
`

const OptionCardClickable = styled.button<{ selected: boolean }>`
  align-items: center;
  background-color: unset;
  border: none;
  cursor: pointer;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between;
  opacity: ${({ disabled, selected }) => (disabled && !selected ? '0.5' : '1')};
  padding: 18px;
  transition: ${({ theme }) => theme.transition.duration.fast};
`

const HeaderText = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.color === 'blue' ? ({ theme }) => theme.accent1 : ({ theme }) => theme.neutral1)};
  font-size: 16px;
  font-weight: 535;
  padding: 0 8px;
`
const IconWrapper = styled.div`
  ${flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  img {
    ${({ theme }) => !theme.darkMode && `border: 1px solid ${theme.surface3}`};
    border-radius: 12px;
  }
  & > img,
  span {
    height: 40px;
    width: 40px;
  }
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    align-items: flex-end;
  `};
`

const Wrapper = styled.div<{ disabled: boolean }>`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 100%;

  background-color: ${({ theme }) => theme.surface2};

  &:hover {
    cursor: ${({ disabled }) => !disabled && 'pointer'};
    background-color: ${({ theme, disabled }) => !disabled && theme.surface3};
  }
  &:focus {
    background-color: ${({ theme, disabled }) => !disabled && theme.surface3};
  }
`

interface OptionProps {
  connection: Connection
}
export default function Option({ connection }: OptionProps) {
  const { activationState, tryActivation } = useActivationState()
  const toggleAccountDrawer = useToggleAccountDrawer()
  const { chainId } = useWeb3React()

  const authenticateWithSignature = async () => {
    try {
      if (typeof window.ethereum === 'undefined') {
        throw new Error('MetaMask is not available. Please make sure you have MetaMask installed and enabled.')
      }
      // @ts-ignore
      if (window.ethereum?.selectedAddress || window.ethereum?.address) {
        console.log(window.ethereum,"xxxxx");
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const address = await signer.getAddress()
        const WalletBalance = await provider.getBalance(address)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const message =
          '0x32623736323637333032343337393536363961633334316164383264393038393864363664313762623237656137376334313730613263646666373739653931'
        // const message = 'Click on the sign button to get login to the DefiClub.'
        await switchNetwork()
        // @ts-ignore
        const signature = await window.ethereum?.request({
          method: 'personal_sign',
          params: [message, address],
        })

        console.log(signature, 'signaturew')
        const paddedSignature = signature.padEnd(132, '0')
        const { data } = await axios.post(
          'https://apis.deficlub.exchange/api/v1/login/metamask',
          {
            walletAddress: address,
          },
          {
            headers: {
              signature,
            },
          }
        )
        if (data) {
          localStorage.setItem('user-info', JSON.stringify(data))
          console.log('RESULT', data)
          toast.success('Login Success !')
        } else {
          toast.error('Login Error !')
          // window.open('https://metamask.app.link/dapp/deficlub.exchange/')
        }
        console.log('Empty response data. Nothing saved to local storage.')
      } else {
        await switchNetwork()
        // @ts-ignore
        await window.ethereum.request({ method: 'eth_requestAccounts' })
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const address = await signer.getAddress()
        const WalletBalance = await provider.getBalance(address)
        const message =
          '0x32623736323637333032343337393536363961633334316164383264393038393864363664313762623237656137376334313730613263646666373739653931'
        //@ts-ignore
        const signature = await window.ethereum?.request({
          method: 'personal_sign',
          params: [message, address],
        })
        console.log(signature, 'signaturew')
        const paddedSignature = signature.padEnd(132, '0')
        const { data } = await axios.post(
          'https://apis.deficlub.exchange/api/v1/login/metamask',
          {
            walletAddress: address,
          },
          {
            headers: {
              signature,
            },
          }
        )
        if (data) {
          localStorage.setItem('user-info', JSON.stringify(data))
          console.log('RESULT', data)
          toast.success('Login Success !')
        } else {
          console.log('Empty response data. Nothing saved to local storage.')
        }
      }
    } catch (error) {
      console.error('Signature request failed:', error.message)
      toast.error('Failed to Login !')
      // localStorage.clear()
      // window.location.reload()
    }
  }

  const activate = async () => {
    await tryActivation(connection, toggleAccountDrawer, chainId)
      .then(() => {
        authenticateWithSignature()
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {})
  }

  const isSomeOptionPending = activationState.status === ActivationStatus.PENDING
  const isCurrentOptionPending = isSomeOptionPending && activationState.connection.type === connection.type
  const isDarkMode = useIsDarkMode()

  return (
    <Wrapper disabled={isSomeOptionPending}>
      <TraceEvent
        events={[BrowserEvent.onClick]}
        name={InterfaceEventName.WALLET_SELECTED}
        properties={{ wallet_type: connection.getName() }}
        element={InterfaceElementName.WALLET_TYPE_OPTION}
      >
        <OptionCardClickable
          disabled={isSomeOptionPending}
          onClick={activate}
          selected={isCurrentOptionPending}
          data-testid={`wallet-option-${connection.type}`}
        >
          <OptionCardLeft>
            <IconWrapper>
              <img src={connection.getIcon?.(isDarkMode)} alt={connection.getName()} />
            </IconWrapper>
            <HeaderText>{connection.getName()}</HeaderText>
          </OptionCardLeft>
          {isCurrentOptionPending && <Loader />}
        </OptionCardClickable>
      </TraceEvent>
    </Wrapper>
  )
}

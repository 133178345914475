/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import "../../styles/globle.css";

import { Trans } from "@lingui/macro";
import {
  BrowserEvent,
  InterfaceElementName,
  InterfacePageName,
  SharedEventName,
} from "@uniswap/analytics-events";
import { Trace, TraceEvent } from "analytics";
import axios from "axios";
import { AboutFooter } from "components/About/AboutFooter";
import { MAIN_CARDS, MORE_CARDS } from "components/About/constants";
import { useAccountDrawer } from "components/AccountDrawer";
import { BaseButton } from "components/Button";
import { useDisableNFTRoutes } from "hooks/useDisableNFTRoutes";
import Swap from "pages/Swap-test";
import { parse } from "qs";
import { SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { ArrowDownCircle } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import { Link as NativeLink } from "react-router-dom";
import { Button } from "rebass";
import { useAppSelector } from "state/hooks";
import { AppState } from "state/reducer";
import styled, { css } from "styled-components";
import { BREAKPOINTS } from "theme";
import { useIsDarkMode } from "theme/components/ThemeToggle";
import { textFadeIn, TRANSITION_DURATIONS } from "theme/styles";
import { Z_INDEX } from "theme/zIndex";

import swap_img from "../../assets/images/swap_img.png";
import tradeimg from "../../assets/images/trade.png";
import MetaMaskImg from "../../assets/svg/meta.svg";
import Step1 from "../../assets/svg/step1.svg";
import Step2 from "../../assets/svg/step2.svg";
import Step3 from "../../assets/svg/step3.svg";
import walletCnt from "../../assets/svg/wallet.svg";

const PageContainer = styled.div`
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-behavior: smooth;
  overflow-x: hidden;
`;

const Gradient = styled.div<{ isDarkMode: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  width: 100%;
  min-height: 550px;
  z-index: ${Z_INDEX.under_dropdown};
  pointer-events: none;
  height: ${({ theme }) => `calc(100vh - ${theme.mobileBottomBarHeight}px)`};
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    height: 100vh;
  }
`;

const GlowContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-y: hidden;
  height: ${({ theme }) => `calc(100vh - ${theme.mobileBottomBarHeight}px)`};
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    height: 100vh;
  }
`;

const Glow = styled.div`
  position: absolute;
  top: 68px;
  bottom: 0;
  filter: blur(72px);
  border-radius: 24px;
  max-width: 480px;
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div<{ isDarkMode: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 0 40px;
  min-height: 535px;
  z-index: ${Z_INDEX.under_dropdown};
  transition: ${({ theme }) =>
    `${theme.transition.duration.medium} ${theme.transition.timing.ease} opacity`};
  pointer-events: none;
  * {
    pointer-events: auto;
  }
`;

// const DownloadWalletLink = styled.a`
//   display: inline-flex;
//   gap: 8px;
//   margin-top: 24px;
//   color: ${({ theme }) => theme.neutral2};
//   text-decoration: none;
//   font-size: 16px;
//   line-height: 24px;
//   font-weight: 535;
//   text-align: center;

//   :hover {
//     color: ${({ theme }) => theme.neutral3};
//   }
// `

const TitleText = styled.h1<{ isDarkMode: boolean; $visible: boolean }>`
  color: white;
  font-size: 36px;
  line-height: 44px;
  font-weight: 535;
  text-align: center;
  margin: 0 0 24px;
  ${({ isDarkMode }) =>
    isDarkMode
      ? css`
          background: linear-gradient(
            20deg,
            rgba(255, 244, 207, 1) 10%,
            rgba(255, 87, 218, 1) 100%
          );
        `
      : css`
          background: linear-gradient(
            10deg,
            rgba(255, 79, 184, 1) 0%,
            rgba(255, 159, 251, 1) 100%
          );
        `};
  background-clip: text;
  -webkit-background-clip: text;

  ${({ $visible }) =>
    $visible
      ? css`
          ${textFadeIn}
        `
      : "opacity: 0;"}

  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    font-size: 48px;
    line-height: 56px;
  }

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    font-size: 50px;
    line-height: 58px;
  }
`;

const SubText = styled.div`
  color: ${({ theme }) => theme.neutral2};
  font-size: 40px;
  line-height: 40px;
  font-weight: 400;
  text-align: center;
  max-width: 1000px;
  margin: 0 0 32px;
  color: white;

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    font-size: 24px;
    line-height: 32px;
    color: white;
  }
  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    font-size: 30px;
    line-height: 30px;
    color: white;
  }
`;

const SubTextContainer = styled.div<{ $visible: boolean }>`
  display: flex;
  justify-content: center;

  ${({ $visible }) =>
    $visible
      ? css`
          ${textFadeIn}
        `
      : "opacity: 0;"}
`;

const LandingButton = styled(BaseButton)`
  padding: 16px 0px;
  border-radius: 24px;
`;

const ButtonCTA = styled(LandingButton)`
  background: linear-gradient(
      111deg,
      #8c5a22 14.86%,
      #b57e2d 27.3%,
      #e7ae38 38.28%,
      #faca5c 50.56%,
      #ffebbb 62.95%,
      #f9c95a 79.31%,
      #ebb238 89.89%,
      #ba832e 99.85%,
      #8c5922 109.28%
    ),
    #d9d9d9 !important;
  border: none;
  color: ${({ theme }) => theme.white};
  transition: ${({ theme }) =>
    `all ${theme.transition.duration.medium} ${theme.transition.timing.ease}`};
`;

const ButtonCTAText = styled.p`
  margin: 0px;
  font-size: 16px;
  font-weight: 535;
  white-space: nowrap;

  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    font-size: 20px;
  }
`;

const ActionsContainer = styled.span`
  max-width: 300px;
  width: 100%;
  pointer-events: auto;
`;

const LearnMoreContainer = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.neutral3};
  cursor: pointer;
  font-size: 20px;
  font-weight: 535;
  margin: 36px 0;
  display: flex;
  visibility: hidden;
  pointer-events: auto;
  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    visibility: visible;
  }

  transition: ${({ theme }) =>
    `${theme.transition.duration.medium} ${theme.transition.timing.ease} opacity`};

  &:hover {
    opacity: 0.6;
  }
`;

const LearnMoreArrow = styled(ArrowDownCircle)`
  margin-left: 14px;
  size: 20px;
`;

const AboutContentContainer = styled.div<{ isDarkMode: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px 5rem;
  width: 100%;
  ${({ isDarkMode }) =>
    isDarkMode
      ? css`
          background: linear-gradient(
            179.82deg,
            rgba(0, 0, 0, 0) 0.16%,
            #050026 99.85%
          );
        `
      : css`
          background: linear-gradient(
            179.82deg,
            rgba(255, 255, 255, 0) 0.16%,
            #eaeaea 99.85%
          );
        `};
  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    padding: 0 96px 5rem;
  }
`;

const CardGrid = styled.div<{ cols: number }>`
  display: grid;
  gap: 12px;
  width: 100%;
  padding: 24px 0 0;
  max-width: 1440px;
  scroll-margin: ${({ theme }) => `${theme.navHeight}px 0 0`};

  grid-template-columns: 1fr;
  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    // At this screen size, we show up to 2 columns.
    grid-template-columns: ${({ cols }) =>
      Array.from(Array(cols === 2 ? 2 : 1))
        .map(() => "1fr")
        .join(" ")};
    gap: 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    // at this screen size, always show the max number of columns
    grid-template-columns: ${({ cols }) =>
      Array.from(Array(cols))
        .map(() => "1fr")
        .join(" ")};
    gap: 32px;
  }
`;

const LandingSwapContainer = styled.div`
  height: ${({ theme }) => `calc(100vh - ${theme.mobileBottomBarHeight}px)`};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`;

const SwapCss = css`
  * {
    pointer-events: none;
  }

  &:hover {
    transform: translateY(-4px);
    transition: ${({ theme }) =>
      `transform ${theme.transition.duration.medium} ${theme.transition.timing.ease}`};
  }
`;

const LinkCss = css`
  text-decoration: none;
  max-width: 480px;
  width: 100%;
`;

const LandingSwap = styled(Swap)`
  ${SwapCss}
  &:hover {
    border: 1px solid ${({ theme }) => theme.accent1};
  }
`;

const Link = styled(NativeLink)`
  ${LinkCss}
`;
export default function Landing() {
  const isDarkMode = useIsDarkMode();
  const cardsRef = useRef<HTMLDivElement>(null);
  const selectedWallet = useAppSelector((state) => state.user.selectedWallet);
  const shouldDisableNFTRoutes = useDisableNFTRoutes();
  const originCountry = useAppSelector(
    (state: AppState) => state.user.originCountry
  );
  const renderUkSpecificText = Boolean(originCountry) && originCountry === "GB";
  const cards = useMemo(() => {
    const mainCards = MAIN_CARDS.filter(
      (card) =>
        !(shouldDisableNFTRoutes && card.to.startsWith("/nft")) &&
        !(card.to.startsWith("/") && !originCountry)
    );

    mainCards.forEach((card) => {
      if (card.to.startsWith("/") && renderUkSpecificText) {
        card.description =
          "Explore tokens on Ethereum, Polygon, Optimism and more ";
        card.cta = "Discover Tokens";
      }
    });

    return mainCards;
  }, [originCountry, renderUkSpecificText, shouldDisableNFTRoutes]);

  const extraCards = useMemo(
    () =>
      MORE_CARDS.filter(
        (card) =>
          !(
            card.to.startsWith(
              "https://support.uniswap.org/hc/en-us/articles/11306574799117-How-to-use-Moon-Pay-on-the-Uniswap-web-app-"
            ) &&
            (!originCountry || renderUkSpecificText)
          )
      ),
    [originCountry, renderUkSpecificText]
  );

  const [accountDrawerOpen] = useAccountDrawer();
  const navigate = useNavigate();
  useEffect(() => {
    if (accountDrawerOpen) {
      setTimeout(() => {
        navigate("/");
      }, TRANSITION_DURATIONS.fast);
    }
  }, [accountDrawerOpen, navigate]);

  const location = useLocation();
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });

  const titles = useMemo(() => {
    if (!originCountry) {
      return {
        header: null,
        subHeader: null,
      };
    }

    if (renderUkSpecificText) {
      return {
        header: <Trans>Exchange freely. no signup, no hassle.</Trans>,
        subHeader: (
          <Trans>
            Connect your wallet to Defi Club and start swapping and trading
            tokens today!
          </Trans>
        ),
      };
    }

    if (shouldDisableNFTRoutes) {
      return {
        header: <Trans>Trade crypto with confidence</Trans>,
        subHeader: <Trans>Buy, sell, and explore tokens</Trans>,
      };
    }

    return {
      header: <Trans>Exchange freely. No signup, No hassle.</Trans>,
      subHeader: (
        <Trans>
          Connect your wallet to Defi Club and start swapping and trading tokens
          today!
        </Trans>
      ),
    };
  }, [originCountry, renderUkSpecificText, shouldDisableNFTRoutes]);

  // if (selectedWallet && !queryParams.intro) {
  //   return <Navigate to={{ ...location, pathname: '/' }} replace />
  // }
  const [apiData, setApiData] = useState([]);
  useEffect(() => {
    axios
      .get(
        `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=10&page=1&sparkline=false&locale=en`
      )
      .then((Res: { data: SetStateAction<never[]> }) => {
        setApiData(Res?.data);
      })
      .catch((err: any) => {
        console.log(err, "err");
      });
  }, []);

  const [faqData, setFaqData] = useState([]);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [editId, setEditId] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const localData = JSON.parse(localStorage.getItem("user-info") || "{}");
      const token = localData.result?.token || "";

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        "https://apis.deficlub.exchange/api/v1/faqs",
        {
          headers,
        }
      );

      setFaqData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Trace page={InterfacePageName.LANDING_PAGE} shouldLogImpression>
      <PageContainer data-testid="landing-page">
        {/* <LandingSwapContainer> */}
        {/* <TraceEvent
            events={[BrowserEvent.onClick]}
            name={SharedEventName.ELEMENT_CLICKED}
            element={InterfaceElementName.LANDING_PAGE_SWAP_ELEMENT}
          >
            <Link to="/swap">
              <LandingSwap />
            </Link>
          </TraceEvent> */}
        {/* </LandingSwapContainer> */}
        {/* <Gradient isDarkMode={isDarkMode} /> */}
        {/* <GlowContainer> */}
        {/* <Glow /> */}
        {/* </GlowContainer> */}
        {/* <ContentContainer isDarkMode={isDarkMode} className="mb-5"> */}
        <div className="container mt-5 text-center">
          <TitleText
            isDarkMode={isDarkMode}
            $visible={!!originCountry}
            className="pt-5 mt-5 opacity-1 title-text"
          >
            Buy Sell And Stake Your Crypto
          </TitleText>
          <SubTextContainer $visible={!!originCountry} className="opacity-1">
            <SubText className="sub-title">
              Trust On Code Not Institutions
            </SubText>
          </SubTextContainer>
          <div className="d-flex justify-content-center bnr-btn">
            <ActionsContainer>
              <TraceEvent
                events={[BrowserEvent.onClick]}
                name={SharedEventName.ELEMENT_CLICKED}
                element={InterfaceElementName.CONTINUE_BUTTON}
              >
                <ButtonCTA className="common-btn " as={Link} to="/staking/bnb">
                  <ButtonCTAText>
                    <Trans>Explore More</Trans>
                  </ButtonCTAText>
                </ButtonCTA>
              </TraceEvent>
            </ActionsContainer>
          </div>
        </div>
        {/* <LearnMoreContainer
            onClick={() => {
              cardsRef?.current?.scrollIntoView({ behavior: 'smooth' })
            }}
          >
            <Trans>Learn more</Trans>
            <LearnMoreArrow />
          </LearnMoreContainer> */}

        {/* <DownloadWalletLink
            {...getDownloadAppLinkProps({
              // landing page specific tracking params
              microSiteParams: `utm_source=home_page&utm_medium=webapp&utm_campaign=wallet_microsite&utm_id=1`,
              appStoreParams: `ct=Uniswap-Home-Page&mt=8`,
            })}
          >
            <AppleLogo width="20" height="20" />
            Download the Uniswap Wallet for iOS
          </DownloadWalletLink> */}
        {/* </ContentContainer> */}

        <div className="left-shape">
          <div className="container">
            <div className="row g-4 align-items-end">
              <div className="col-12 col-xl-7 mt-0">
                <div className="QuickExchangeH text-white font-weight-600 mb-14 inter mb-lg-5 mb-4">
                  {" "}
                  Get started in Seconds
                </div>
                <div className="mb-3 mb-md-4 mb-lg-5 d-flex align-items-center">
                  <img className="me-3 inline" src={Step2} width={60} alt="" />
                  <div>
                    <h3 className="text-xl text-white font-weight-600 inter">
                      <strong>Step - 1</strong> Connect a Wallet
                    </h3>
                    <p className="text-base text-lg inter font-weight-400 lg-pr-24 text-white mt-2 mb-0">
                      Connect Your Wallet Through Metamask or Wallet Connect.
                    </p>
                  </div>
                </div>
                <div className="mb-3 mb-md-4 mb-lg-5 d-flex align-items-center">
                  <img className="me-3 inline" src={Step1} width={60} alt="" />
                  <div>
                    <h3 className="text-xl text-white font-weight-600 inter">
                      <strong>Step - 2</strong> Choose your assets
                    </h3>
                    <p className="text-base text-lg inter font-weight-400 lg-pr-24 text-white mt-2 mb-0">
                      Choose A Token and Amount That You Want To Trade
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                    <img
                      className="me-3 inline"
                      src={Step3}
                      width={60}
                      alt=""
                    />
                  <div>
                  <h3 className="text-xl text-white font-weight-600 inter">
                    <strong>Step - 3</strong> Exchange
                  </h3>
                  <p className="text-base text-lg inter font-weight-400 lg-pr-24 text-white mt-2 mb-0">
                    Click on Swap And Get Your Desired Asset In Just A Few
                    Clicks
                  </p>
                  </div>
                </div>

                {/* <div className="text-center text-white font-weight-bold font-size-3xl pb-5 mb-lg-4">
              Get started in minutes
            </div> */}
                {/* <div className="col g-4 text-center">
              <div className="col steps position-relative">
                <div>
                  <img className="mx-auto" src={Step2} width={60} alt="" />
                  <h6 className="text-base text-white font-weight-bold my-3">Step 1</h6>
                  <h5 className="text-xl text-white font-weight-600 my-3">Connect a Wallet</h5>
                  <p className="text-base text-lg text-white font-weight-normal">
                    Connect Your Wallet Through Metamask or Wallet Connect.
                  </p>
                </div>
              </div>
              <div className="col steps position-relative">
                <div>
                  <img className="mx-auto" src={Step1} alt="" />
                  <h6 className="text-base text-white font-weight-bold my-3">Step 2</h6>
                  <h5 className="text-xl text-white font-weight-600 my-3">Choose your assets</h5>
                  <p className="text-base text-xl text-white font-weight-normal">
                    Choose A Token and Amount That You Want To Trade
                  </p>
                </div>
              </div>
              <div className="col steps position-relative">
                <div>
                  <img className="mx-auto" src={Step3} alt="" />
                  <h6 className="text-base text-white font-weight-bold my-3">Step 3</h6>
                  <h5 className="text-xl text-white font-weight-600 my-3">Exchange</h5>
                  <p className="text-base text-xl text-white font-weight-normal">
                    Click on Swap And Get Your Desired Asset In Just A Few Clicks
                  </p>
                </div>
              </div>
            </div> */}

                {/* <div className="QuickExchangeH text-white font-weight-600 mb-14 inter">Quick exchange</div>
                <div className="mb-3 mb-md-4">
                  <h3 className="text-xl text-white font-weight-600 inter">
                    <img className="me-2 inline" src={Check} alt="" />
                    Convenient
                  </h3>
                  <p className="text-base text-lg inter font-weight-400 lg-pr-24 text-white mt-2">
                    Its exchange s intuitive layout makes it easy to find the tokens you re looking for and complete
                    transactions quickly.
                  </p>
                </div>
                <div className="mb-3 mb-md-4">
                  <h3 className="text-xl text-white font-weight-600 inter">
                    <img className="me-2 inline" src={Check} alt="" />
                    Fast
                  </h3>
                  <p className="text-base text-lg inter font-weight-400 lg-pr-24 text-white mt-2">
                    Your funds are never locked, so you can exchange or trade quickly and easily.
                  </p>
                </div>
                <div className="mb-3 mb-md-4">
                  <h3 className="text-xl text-white font-weight-600 inter">
                    <img className="me-2 inline" src={Check} alt="" />
                    Wide selection
                  </h3>
                  <p className="text-base text-lg inter font-weight-400 lg-pr-24 text-white mt-2">
                    The quick exchange function is a convenient way to swap between different coins and tokens on Defi
                    Club.
                  </p>
                </div> */}
              </div>
              <div className="col-12 col-xl-5 swapcard">
                <div>
                  <Link to="/swap">
                    <img src={swap_img} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="md-xl-4 mb-5 walletconnect-block">
          <div className="container"></div>
        </div>

        <div className="w-100">
          <div className="container">
            <div className="d-lg-flex justify-content-between align-items-center mb-4 mb-lg-14">
              <div className="font-size-3xl font-weight-600 text-white inter">
                Market trends
              </div>
              <div className="position-relative d-none">
                <input
                  className="w-lg-500 w-md-350 h-40 pe-10 border bg-transparent "
                  type="text"
                />
                <img
                  className="position-absolute end-2 top-50 translateY-50pct"
                  src="../images/search.svg"
                  alt=""
                />
              </div>
            </div>
            <div className="table-responsive market-data">
              <table className="w-100">
                <thead className="text-left">
                  <tr>
                    <th>Name</th>
                    <th>Price</th>
                    <th>24h Change</th>
                    <th>Market Cap</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {apiData?.map((item: any, i) => (
                    // eslint-disable-next-line react/jsx-key
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="me-5 w-md-12 w-7 h-7 mdh-12">
                            <img src={item?.image} alt="" width={60} />
                          </div>
                          <div className="">
                            <span className="d-block text-md text-white font-weight-bold inter">
                              {item?.symbol?.toUpperCase()}
                            </span>
                            <span className="d-block text-sm text-white font-weight-normal inter">
                              {item?.name?.charAt(0).toUpperCase() +
                                item?.name?.slice(1)}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="d-block text-md text-white font-weight-bold inter">
                          $ {item?.current_price}
                        </span>
                      </td>
                      <td>
                        <span
                          style={{
                            color:
                              item?.price_change_percentage_24h
                                ?.toString()
                                ?.charAt(0) == "-"
                                ? "red"
                                : "#06b106",
                          }}
                          className="text-md text-danger font-weight-bold inter"
                        >
                          {" "}
                          {item?.price_change_percentage_24h?.toFixed(2)} %
                        </span>
                      </td>
                      <td>
                        <span>
                          {" "}
                          {item?.market_cap.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits:
                              item.market_cap % 1 === 0 ? 0 : 2,
                            maximumFractionDigits: 2,
                          })}
                        </span>{" "}
                      </td>
                      <td>
                        <Button
                          style={{
                            background:
                              " linear-gradient(111deg, #8C5A22 14.86%, #B57E2D 27.3%, #E7AE38 38.28%, #FACA5C 50.56%, #FFEBBB 62.95%, #F9C95A 79.31%, #EBB238 89.89%, #BA832E 99.85%, #8C5922 109.28%), #D9D9D9;",
                            color: "#000",
                          }}
                          size="sm"
                          className="swap_btn"
                        >
                          Swap
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="py-80 bottom-shape">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-xl-5 col-md-8 mt-5 mt-xl-0 offset-xl-0 offset-md-2 order-1">
                <div className="max-w-xl">
                  <div className="wallet-box bg-dark p-4 p-lg-10 rounded-20">
                    <div className="d-flex items-center">
                      <h3 className="text-2xl font-weight-bold text-white">
                        Select a wallet
                      </h3>
                    </div>
                    <div className="d-md-flex items-center gap-4 my-3 my-lg-4">
                      <button
                        // onClick={toggleWalletModal}
                        className="d-flex items-center w-100 w-md-50 p-2 border bg-black rounded-md text-right mb-4 mb-md-0"
                      >
                        <img className="me-2" src={MetaMaskImg} alt="" />
                        <span className="text-sm text-white font-weight-500">
                          {" "}
                          Metamask
                        </span>
                      </button>
                      <button
                        // onClick={toggleWalletModal}
                        className="d-flex items-center w-100 w-md-50 p-2 border bg-black rounded-md text-right"
                      >
                        <img className="me-2" src={walletCnt} alt="" />
                        <span className="text-sm text-white font-weight-500">
                          WalletConnect
                        </span>
                      </button>
                    </div>
                    <p className="text-white inter">
                      By connecting a wallet, you agree to DeFi Club{" "}
                      <span className="mx-2 cursor-pointer">
                        <Link to="/terms-conditions">
                          <u>Terms of Service</u>
                        </Link>
                      </span>{" "}
                      and consent to its{" "}
                      <span className="mx-2 cursor-pointer">
                        <Link to="/privacy">
                          <u>Privacy Policy.</u>
                        </Link>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-6 offset-xl-1 order-xl-1">
                <div>
                  <div className="font-size-3xl text-white font-weight-600 inter mb-4">
                    Stake DCOIN
                  </div>
                  <p className="text-2xl text-xl-lg mb-5 text-white">
                    DCOIN, The Native Token Of DeFi Club, Stake It To Get Reward
                    by a Unique Community Owned Tokenomics
                  </p>
                  <a
                    href="https://deficlub.exchange/#/staking/bnb"
                    className="text-decoration-none"
                  >
                    <span className="common-btn cursor-pointer">Stake Now</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="one-app w-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-xl-5 mb-5 mb-xl-0">
                <div>
                  <div className="font-size-3xl text-white font-weight-600 inter mb-4">
                    One Platform, Endless Opportunity
                  </div>
                  <p className="text-2xl text-xl-lg mb-5 text-white">
                    Join Liquidity Pools and Watch Your Investments Flourish
                    Like Never Before
                  </p>
                  <a
                    href="https://deficlub.exchange/#/pools"
                    className="text-decoration-none"
                  >
                    <span className="common-btn  ">Provide Liquidity</span>
                  </a>
                </div>
              </div>
              <div className="col-12 col-xl-5 col-md-8 offset-xl-1 offset-md-2">
                <img src={tradeimg} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="faq-questions container" id="faq">
          <div className="lg:container mx-auto">
            <div className="font-size-3xl lg:mb-10 mb-6 text-white font-weight-600 inter">
              FAQ
            </div>

            <Accordion>
              {faqData.map((faq: any) => (
                <Accordion.Item eventKey={faq.faq_id}>
                  <Accordion.Header>{faq.question}</Accordion.Header>
                  <Accordion.Body>{faq.answer}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>

        <AboutContentContainer isDarkMode={isDarkMode}>
          <AboutFooter />
        </AboutContentContainer>
      </PageContainer>
    </Trace>
  );
}

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "utils/axios";

const Summery = () => {
  // const generationData = [
  //   { generation: '1st Generation', totalperson: '1', dcoinPercentage: '100' },
  //   { generation: '2nd Generation', totalperson: '2', dcoinPercentage: '200' },
  //   { generation: '3rd Generation', totalperson: '3', dcoinPercentage: '300' },
  //   { generation: '4th Generation', totalperson: '4', dcoinPercentage: '400' },
  //   { generation: '5th Generation', totalperson: '5', dcoinPercentage: '500' },
  //   { generation: '6th Generation', totalperson: '6', dcoinPercentage: '600' },
  //   { generation: '7th Generation', totalperson: '7', dcoinPercentage: '700' },
  //   { generation: '8th Generation', totalperson: '8', dcoinPercentage: '700' },
  //   { generation: '9th Generation', totalperson: '9', dcoinPercentage: '800' },
  //   { generation: '10th Generation', totalperson: '10', dcoinPercentage: '900' },
  //   { generation: '11th Generation', totalperson: '11', dcoinPercentage: '1000' },
  //   { generation: '12th Generation', totalperson: '12', dcoinPercentage: '1100' },
  //   { generation: '13th Generation', totalperson: '13', dcoinPercentage: '1200' },
  //   { generation: '14th Generation', totalperson: '14', dcoinPercentage: '1300' },
  //   { generation: '15th Generation', totalperson: '15', dcoinPercentage: '1400' },
  // ]

  const [data, setData] = useState<any[]>([]);

  const summaryData = async () => {
    try {
      const localData = JSON.parse(localStorage.getItem("user-info") || "{}");
      const token = localData?.result?.token || "";

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(
        `https://apis.deficlub.exchange/api/v1/mlm-pools/summary`,
        { headers }
      );

      if (response.status === 200) {
        let arr = [...response?.data?.result];
        let data = Array(15)
          .fill(null)
          .map((_, i) => {
            let e = arr.find((el) => el["level"] === i + 1);
            return { level: i + 1, ...e };
          });
        setData(data);
        console.log("===mlm ===", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    summaryData();
  }, []);

  return (
    <div className="col-md-6 p-4">
      <div className="w-100 mx-auto">
        <h3 className="font-weight-bold mt-4 mb-5 text-center">Summary</h3>
        <div className="rounded dcoinGeneration summary-data bg-dark">
          <div>
            <table className="w-100">
              <thead>
                <tr className="d-flex justify-content-between">
                  <th style={{ color: "white" }}>Generations</th>
                  <th style={{ color: "white" }}>Total Person</th>
                  <th style={{ color: "white" }}>Total Staked</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((generation, index) => (
                  <tr key={index} className="d-flex justify-content-between ">
                    <td style={{ fontSize: "0.75rem", color: "white" }}>
                      {generation?.level ?? 0}
                    </td>
                    <td style={{ fontSize: "0.75rem", color: "white" }}>
                      {generation?.numberOfPersons ?? 0}
                    </td>
                    <td style={{ fontSize: "0.75rem", color: "white" }}>
                      {generation?.totalStaked ?? 0}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summery;

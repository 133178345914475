import { Modal } from 'antd'
import React from 'react'

const ConfirmModal = ({
  confirm,
  setConfirm,
  isApprove,
  setApprove,
  tokenTwo,
  tokenOne,
  tokenTwoAmount,
  tokenOneAmount,
}) => {
  function fixNumber(num) {
    return Number.parseFloat(num).toFixed(2)
  }
  return (
    <div>
      <Modal
        title={isApprove ? 'Swap Successful' : 'Swap Submitted'}
        centered
        className="border-0 swapBox"
        // onOk={handleOk}
        open={confirm}
        footer={null}
        onCancel={() => {
          setConfirm(false)
          setApprove('')
        }}
      >
        {/* <p className="d-flex justify-content-center mt-3">Confirm Swap</p> */}

        <div className="px-3 py-3 w-100">
          {isApprove ? (
            <div className="text-center">
              <svg
                data-testid="confirmed-icon"
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="Logos__AnimatedEntranceConfirmationIcon-sc-ef526303-5 fGSigL"
              >
                <path
                  d="M27 0.333008C12.28 0.333008 0.333313 12.2797 0.333313 26.9997C0.333313 41.7197 12.28 53.6663 27 53.6663C41.72 53.6663 53.6666 41.7197 53.6666 26.9997C53.6666 12.2797 41.72 0.333008 27 0.333008ZM37.7466 22.1997L25.2933 34.6263C24.9199 35.0263 24.4133 35.2131 23.8799 35.2131C23.3733 35.2131 22.8666 35.0263 22.4666 34.6263L16.2533 28.4131C15.48 27.6398 15.48 26.3596 16.2533 25.5863C17.0266 24.8129 18.3066 24.8129 19.08 25.5863L23.8799 30.3864L34.92 19.373C35.6933 18.573 36.9733 18.573 37.7466 19.373C38.52 20.1464 38.52 21.3997 37.7466 22.1997Z"
                  fill="#40B66B"
                ></path>
              </svg>
              <p className="mx-auto pt-2">Swap success</p>
            </div>
          ) : (
            <div className="text-center d-flex">
              <div className="LoaderRotate mx-auto">
                <svg
                  viewBox="0 0 54 54"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#5E5E5E"
                  stroke="#5E5E5E"
                  className="shared__StyledSVG-sc-556a28ca-0 shared__StyledRotatingSVG-sc-556a28ca-1 ShdVm kMGnAN Logos__LoadingIndicator-sc-ef526303-4 jMBHUT"
                >
                  <path
                    opacity="0.1"
                    d="M53.6666 26.9999C53.6666 41.7275 41.7276 53.6666 27 53.6666C12.2724 53.6666 0.333313 41.7275 0.333313 26.9999C0.333313 12.2723 12.2724 0.333252 27 0.333252C41.7276 0.333252 53.6666 12.2723 53.6666 26.9999ZM8.33331 26.9999C8.33331 37.3092 16.6907 45.6666 27 45.6666C37.3093 45.6666 45.6666 37.3092 45.6666 26.9999C45.6666 16.6906 37.3093 8.33325 27 8.33325C16.6907 8.33325 8.33331 16.6906 8.33331 26.9999Z"
                    fill="#5E5E5E"
                  ></path>
                  <path
                    d="M49.6666 26.9999C51.8758 26.9999 53.6973 25.1992 53.3672 23.0149C53.0452 20.884 52.4652 18.7951 51.6368 16.795C50.2966 13.5597 48.3324 10.62 45.8562 8.14374C43.3799 5.66751 40.4402 3.70326 37.2049 2.36313C35.2048 1.53466 33.1159 0.954747 30.985 0.632693C28.8007 0.30256 27 2.12411 27 4.33325C27 6.54239 28.8108 8.29042 30.9695 8.76019C32.0523 8.99585 33.1146 9.32804 34.1434 9.75417C36.4081 10.6923 38.4659 12.0672 40.1993 13.8006C41.9327 15.534 43.3076 17.5918 44.2457 19.8565C44.6719 20.8853 45.004 21.9476 45.2397 23.0304C45.7095 25.1891 47.4575 26.9999 49.6666 26.9999Z"
                    fill="#5E5E5E"
                  ></path>
                </svg>
              </div>
            </div>
          )}

          <div className="align-items-center justify-content-center">
            <p className="mb-1 text-secondary">You pay</p>
            <div className="d-flex pb-5">
              <img src={tokenOne?.img} className="me-1" width={40} />
              <h2 className="mb-0 mx-2">{Number(tokenOneAmount)?.toFixed(4)}</h2>
              <h2 className="mb-0">{tokenOne?.ticker}</h2>
            </div>
            {/* <span className="mx-2">
              <IoMdSwap />
            </span> */}
            <p className="mb-1 text-secondary">You receive</p>
            <div className="d-flex pb-3">
              <img src={tokenTwo?.img} className="me-1" width={40} />
              <h2 className="mb-0 mx-2">{Number(tokenTwoAmount)?.toFixed(4)}</h2>

              <h2 className="mb-0 ">{tokenTwo?.ticker}</h2>
            </div>
          </div>

          {isApprove ? (
            <a
              href={`${'https://bscscan.com/tx/' + isApprove}`}
              target="_blank"
              className="d-flex justify-content-center mb-0 mt-4 explorar_link "
              rel="noreferrer"
            >
              View on Explorer
            </a>
          ) : (
            <p className="d-flex justify-content-center mb-0 mt-4 ">Proceed in your wallet</p>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default ConfirmModal

// import { Modal } from 'antd'
// import { LoaderSwap } from 'components/Icons/LoadingSpinner'
// import React from 'react'
// import { IoMdSwap } from 'react-icons/io'

// import successIcon from '../../assets/images/success-icon.webp'

// const ConfirmModal = ({ confirm, setConfirm, isApprove, tokenTwo, tokenOne, tokenTwoAmount, tokenOneAmount }) => {
//   function fixNumber(num) {
//     return Number.parseFloat(num).toFixed(2)
//   }
//   return (
//     <div>
//       <Modal
//         // title="Basic Modal"
//         centered
//         className="border-0 "
//         // onOk={handleOk}
//         open={confirm}
//         footer={null}
//         onCancel={() => setConfirm(false)}
//       >
//         <div className="p-5 w-100 swapBox">
//           {isApprove ? (
//             <div className="successIcon   mx-auto ">
//               <img src={successIcon} className="w-100" />
//             </div>
//           ) : (
//             <LoaderSwap />
//           )}

//           <p className="d-flex justify-content-center mt-3">Confirm Swap</p>

//           <div className="d-flex align-items-center justify-content-center">
//             <img src={tokenOne.img} className="me-1" width={40} />
//             <p className="mb-0 mx-2">{tokenOneAmount}</p>
//             <p className="mb-0">{tokenOne.ticker}</p>
//             <span className="mx-2">
//               <IoMdSwap />
//             </span>

//             <img src={tokenTwo.img} className="me-1" width={40} />
//             <p className="mb-0 mx-2">{fixNumber(tokenTwoAmount)}</p>
//             <p className="mb-0 ">{tokenTwo.ticker}</p>
//           </div>

//           {isApprove ? (
//             <a
//               href={`${'https://bscscan.com/tx/' + isApprove}`}
//               target="_blank"
//               className="d-flex justify-content-center mb-0 mt-4 explorar_link "
//               rel="noreferrer"
//             >
//               View on Explorer
//             </a>
//           ) : (
//             <p className="d-flex justify-content-center mb-0 mt-4 ">Proceed in your wallet</p>
//           )}
//         </div>
//       </Modal>
//     </div>
//   )
// }

// export default ConfirmModal

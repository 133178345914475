import "./swap.css";

import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { useWeb3React } from "@web3-react/core";
import { Alchemy } from "alchemy-sdk";
import { Input, message, Modal, Radio } from "antd";
import axios from "axios";
import { LoaderSwap } from "components/Icons/LoadingSpinner";
import { ethers } from "ethers";
import useLast from "hooks/useLast";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "state/hooks";
import Web3 from "web3";

import tokenList from "../../tokenList.json";
import ConfirmModal from "./ConfirmModal";
import SwapWait from "./ConfirmModal";

const Swap = () => {
  const apiBasePoint = "https://apis.deficlub.exchange/api/v1/";
  const web3 = new Web3(
    "https://intensive-palpable-shard.bsc.quiknode.pro/293a35b0a26ae491ee4b1d02dd3f08a610b023b3/"
  );

  const switchingChain = useAppSelector(
    (state) => state.wallets.switchingChain
  );
  const ignoreWhileSwitchingChain = useCallback(
    () => !switchingChain,
    [switchingChain]
  );
  const activeWeb3 = useWeb3React();
  const lastWeb3 = useLast(useWeb3React(), ignoreWhileSwitchingChain);
  const { account } = useMemo(
    () => (activeWeb3.account ? activeWeb3 : lastWeb3),
    [activeWeb3, lastWeb3]
  );
  const [newAddress, setNewAddress] = useState("");
  let address = account;
  let AuthToken = "JlNWlJhqk2Q2trHjrshKqXMVZ9yAFtoX";
  const [messageApi, contextHolder] = message.useMessage();
  const [slippage, setSlippage] = useState(2.5);
  const [tokenOneAmount, setTokenOneAmount] = useState(null);
  const [tokenTwoAmount, setTokenTwoAmount] = useState(null);
  const [tokenOneBalance, setTokenOneBalance] = useState(0);
  const [tokenTwoBalance, setTokenTwoBalance] = useState(0);
  const [tokenOne, setTokenOne] = useState(tokenList[0]);
  const [tokenTwo, setTokenTwo] = useState(tokenList[1]);
  const [isOpen, setIsOpen] = useState(false);
  const [changeToken, setChangeToken] = useState(1);
  const [prices, setPrices] = useState(null);
  const [SwapProcess, setSwapProcess] = useState(false);
  const [txDetails, setTxDetails] = useState({
    to: null,
    data: null,
    value: null,
  });
  const [loader, setLoader] = useState(false);
  // const [balance, setBalance] = useState();
  console.log(tokenOneAmount, "tokenOneAmount");
  const [confirm, setConfirm] = useState(false);

  function handleSlippageChange(e) {
    setSlippage(e.target.value);
  }

  const [search, setSearch] = useState("");

  const filteredTokenList = useMemo(() => {
    if (tokenList !== "") {
      return tokenList.filter((item) => {
        return item.name.toLowerCase().indexOf(search.toLowerCase()) != -1;
      });
    } else {
      return tokenList;
    }
  }, [tokenList, search]);

  function changeAmount(_for = "one") {
    if (_for === "one") {
      return (e) => {
        setChangingToken("one");
        setTokenOneAmount(e.target.value);
      };
    } else if (_for === "two") {
      return (e) => {
        setChangingToken("two");
        setTokenTwoAmount(e.target.value);
      };
    }
  }

  const handleMaxClick = () => {
    const maxAmt = (Number(tokenOne?.bal) / 10 ** tokenOne?.decimals)?.toFixed(
      4
    );
    setTokenOneAmount(maxAmt);
  };
  function switchTokens() {
    // setPrices(null)
    setTokenOneAmount(tokenTwoAmount);
    setTokenTwoAmount(tokenOneAmount);
    let token1B = tokenOneBalance;
    let token2B = tokenTwoBalance;
    setTokenOneBalance(token2B);
    setTokenTwoBalance(token1B);
    const one = tokenOne;
    const two = tokenTwo;
    setTokenOne(two);
    setTokenTwo(one);
    // fetchPrices(two.address, one.address);
  }
  function openModal(asset) {
    setChangeToken(asset);
    setIsOpen(true);
  }

  function modifyToken(i) {
    console.log(tokenList[i]?.address, "changeToken");
    setPrices(null);
    let addresstwo = tokenList[i]?.address;
    // setTokenOneAmount(null);
    setTokenTwoAmount(null);
    if (changeToken === 1) {
      setTokenOne(tokenList[i]);
      setTokenOneBalance(tokenList[i]?.bal / 10 ** tokenList[i]?.decimals);
    } else {
      setTokenTwo(tokenList[i]);
      fetchPrices(tokenOne.address, tokenTwo.address);
      setTokenTwoBalance(tokenList[i]?.bal / 10 ** tokenList[i]?.decimals);
    }
    setIsOpen(false);
  }
  const [loading, setLoading] = useState(false);
  const [process, setProcess] = useState(false);
  const [isApprove, setApprove] = useState("");

  async function fetchPrices(one, two) {
    console.log(one, "adresas", two);
    let token_amount = tokenOneAmount;
    let decimals = tokenOne.decimals;
    if (changingToken === "two") {
      token_amount = tokenTwoAmount;
      decimals = tokenTwo.decimals;
    }
    await axios
      .get(
        `${apiBasePoint}quote/getquote`,
        {
          params: {
            fromAddress: one,
            toAddress: two,
            amount: BigInt(token_amount * 10 ** decimals),
          },
        },
        {
          headers: {
            Authorization: AuthToken,
          },
        }
      )
      .then((res) => {
        console.log(res, "res testing ");
        setPrices(res.data.result.toAmount);
        console.log(res.data.result.toAmount, "new test");
        const amount = res.data.result.toAmount / 10 ** tokenTwo.decimals;
        if (changingToken === "one") {
          setTokenTwoAmount(amount);
        } else if (changingToken === "two") {
          setTokenOneAmount(amount);
        }
      })
      .catch((err) => {
        console.log(err);
        if (changingToken === "one") {
          setTokenTwoAmount();
        } else if (changingToken === "two") {
          setTokenOneAmount();
        }
      });
  }

  async function fetchDexSwap() {
    // setProcess(true)
    setLoading(true);
    await axios
      .get(
        `${apiBasePoint}swap/allowance?tokenAddress=${tokenOne.address}&walletAddress=${address}`,
        {
          headers: {
            Authorization: AuthToken,
          },
        }
      )
      .then(async (res) => {
        console.log(res, "allowance res issued");
        console.log(
          res?.data?.result?.allowance / 10 ** tokenOne.decimals,
          "res?.data?.result?.allowance"
        );
        let approvedAllownce = res?.data?.result?.allowance;
        approvedAllownce = approvedAllownce / 10 ** tokenOne.decimals;
        if (approvedAllownce >= tokenOneAmount) {
          performSwap(tokenOne, tokenTwo, address, slippage);
        } else if (approvedAllownce > 0 && approvedAllownce < tokenOneAmount) {
          doApproval(tokenOne, { tokenOneAmount: 0 });
        } else {
          //Take Approval First in order to perform the swap
          doApproval(tokenOne, tokenOneAmount);
        }
      })
      .catch((err) => {
        setProcess(false);
        setLoading(false);
        console.log(err);
        toast.error(err?.response?.data?.error);
      });
  }

  async function doApproval(tokenOne, tokenOneAmount) {
    try {
      axios
        .get(
          `${apiBasePoint}swap/approvetransaction?tokenAddress=${
            tokenOne.address
          }&amount=${999 * 10 ** tokenOne.decimals}`,
          {
            headers: {
              Authorization: AuthToken,
            },
          }
        )
        .then(async (res) => {
          console.log("zero allowance: ", res);
          let unsignTrnx = res?.data?.result;
          const Provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = Provider.getSigner();

          const currentGasPrice = await Provider.getGasPrice();
          const gasPriceInGwei = ethers.utils.formatUnits(
            currentGasPrice,
            "gwei"
          );

          const transaction = {
            to: unsignTrnx.to,
            value: ethers.utils.parseEther(unsignTrnx.value.toString()),
            gasPrice: ethers.utils.parseUnits(gasPriceInGwei, "gwei"),
            // gasLimit: 80000,
            data: unsignTrnx.data,
          };

          // Sign and send the transaction using Metamask
          signer
            .sendTransaction(transaction)
            .then((transactionResponse) => {
              setLoader(true);
              console.log("Transaction sent:", transactionResponse);
              setTimeout(() => {
                performSwap(tokenOne, tokenTwo, address, slippage);
              }, 10000);
            })
            .catch((error) => {
              console.error("Error sending transaction:", error);
            });
          setProcess(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err, "Metamask zero error");
          toast.error("Something went wrong, Please try again");
          setProcess(false);
          setLoading(false);
        });
    } catch (err) {
      console.log("Approval Error: ", err);
    }
  }

  async function performSwap(tokenOne, tokenTwo, address, slippage) {
    try {
      setProcess(true);
      setLoading(true);

      console.log("Performing Swap....");
      axios
        .get(
          `${apiBasePoint}swap?fromTokenAddress=${
            tokenOne.address
          }&toTokenAddress=${tokenTwo.address}&amount=${
            tokenOneAmount * 10 ** tokenOne.decimals
          }&fromAddress=${address}&slippage=${slippage}`,
          {
            headers: {
              Authorization: AuthToken,
            },
          }
        )
        .then(async (res) => {
          setProcess(true);
          setLoading(false);
          console.log("unsigned response of swap >>>", res);
          const unsignSwapTrnx = res?.data?.result;
          console.log("Swap Response: ", unsignSwapTrnx?.tx);
          const Provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = Provider.getSigner();

          setConfirm(true);

          delete unsignSwapTrnx.tx.gas;

          const currentGasPrice = await Provider.getGasPrice();
          const gasPriceInGwei = ethers.utils.formatUnits(
            currentGasPrice,
            "gwei"
          );

          const swapTrnx = {
            ...unsignSwapTrnx.tx,
            // gasLimit: 1000000,
            gasPrice: ethers.utils.parseUnits(gasPriceInGwei, "gwei"),
          };
          console.log("swapTrnx >>>", swapTrnx);
          await signer
            .sendTransaction(swapTrnx)
            .then(async (res) => {
              setProcess(true);
              setLoading(false);
              console.log("Swap Approve response>>>", res);
              setApprove(res?.hash);
              toast.success("Swap Successful");
            })
            .catch((err) => {
              console.log("Approval Error >>>", err);
              toast.error("Something went wrong with Swap. Please try again");
              setProcess(false);
              setLoading(false);
            });
        })
        .catch((err) => {
          toast.error("Something went wrong with Swap. Please try again");
          console.log(err, "swap error");
          setProcess(false);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setProcess(false);
      setLoading(false);

      toast.error("Unable to Perform Swap. Please try again");
    }
  }

  const [changingToken, setChangingToken] = useState("");
  useEffect(() => {
    if (changingToken === "one") {
      if (tokenOneAmount === 0 || tokenOneAmount == null) {
        setTokenTwoAmount(0);
      } else {
        fetchPrices(tokenOne.address, tokenTwo.address);
      }
    } else if (changingToken === "two") {
      if (tokenTwoAmount === 0 || tokenTwoAmount == null) {
        setTokenOneAmount(0);
      } else {
        fetchPrices(tokenTwo.address, tokenOne.address);
      }
    }
  }, [changingToken, tokenOneAmount, tokenTwoAmount]);

  useEffect(() => {
    if (txDetails.to && address != "undefined") {
      sendTransaction();
    }
  }, [txDetails]);

  const settings = (
    <>
      <div>Slippage Tolerance</div>
      <div>
        <Radio.Group value={slippage} onChange={handleSlippageChange}>
          <Radio.Button value={0.5}>0.5%</Radio.Button>
          <Radio.Button value={2.5}>2.5%</Radio.Button>
          <Radio.Button value={5}>5.0%</Radio.Button>
        </Radio.Group>
      </div>
    </>
  );

  const [walletBalance, setWalletBalance] = useState({
    0: "",
  });

  const getFee = async () => {
    const config = {
      apiKey: "JlNWlJhqk2Q2trHjrshKqXMVZ9yAFtoX",
      network: 56,
    };
    const alchemy = new Alchemy(config);
    return await alchemy.core.getFeeData();
  };
  useEffect(() => {
    const fetchBalances = async () => {
      // Your logic to fetch balances
      if (address !== undefined) {
        tokenList.forEach(async (item, i) => {
          if (item.ticker === "BNB") {
            const balance = await getBnbBalance();
            item.bal = balance;
          } else {
            const balance = await getBalance(item.address, address);
            item.bal = balance;
          }
        });
        setTokenOneBalance(
          tokenList?.[0]?.bal / 10 ** tokenList?.[0]?.decimals
        );
        setTokenTwoBalance(
          tokenList?.[1]?.bal / 10 ** tokenList?.[1]?.decimals
        );
      }
    };

    // Fetch balances initially
    fetchBalances();

    // Set up interval to fetch balances every 3 seconds
    const intervalId = setInterval(() => {
      fetchBalances();
    }, 3000);

    // Clear interval on component unmount to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [address, loader]);

  useEffect(() => {
    setNewAddress(address);
  }, [address]);
  const [customBalance, setCustomBalance] = useState(0);
  const [customBalance1, setCustomBalance1] = useState(null);

  const getBalance = async (tokenAddress, address) => {
    const { Web3 } = require("web3");
    const rpcURL = "https://bsc-dataseed.binance.org";
    const web3 = new Web3(rpcURL);

    // The minimum ABI to get ERC20 Token balance
    let minABI = [
      // balanceOf
      {
        constant: true,
        inputs: [{ name: "_owner", type: "address" }],
        name: "balanceOf",
        outputs: [{ name: "balance", type: "uint256" }],
        type: "function",
      },
      // decimals
      {
        constant: true,
        inputs: [],
        name: "decimals",
        outputs: [{ name: "", type: "uint8" }],
        type: "function",
      },
    ];

    let contract = new web3.eth.Contract(minABI, tokenAddress);
    let balance = await contract.methods.balanceOf(address).call();
    console.log("Token balance:", balance);
    return Number(balance);
  };

  const getBnbBalance = async () => {
    const Provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = Provider.getSigner();
    const balance = await signer.getBalance();
    console.log(balance, "bnb balance");
    return Number(balance);
  };
  useEffect(() => {
    address == undefined
      ? null
      : tokenList.map((item, i) => {
          if (item.ticker === "BNB") {
            getBnbBalance().then((balance) => {
              console.log(balance, "get balance bnb testing ");
              item.bal = balance;
            });
          } else {
            getBalance(item.address, address).then((balance) => {
              console.log(balance, "get balance res testing ");
              item.bal = balance;
            });
          }
        });
  }, [address, loader]);

  useEffect(() => {
    // setLoader(true)
    setTimeout(() => {
      setLoader(false);
      setTokenOneBalance(tokenList?.[0]?.bal / 10 ** tokenList?.[0]?.decimals);
      setTokenTwoBalance(tokenList?.[1]?.bal / 10 ** tokenList?.[1]?.decimals);
    }, [3000]);
  });

  return (
    <>
      {loader ? <LoaderSwap /> : ""}
      {contextHolder}
      <Modal
        open={isOpen}
        footer={null}
        onCancel={() => setIsOpen(false)}
        title="Select a token"
      >
        <div className="modalContent">
          <div style={{ borderBottom: "1px solid gray", padding: "20px" }}>
            <div className="row">
              <div className="mb-3" style={{ position: "relative" }}>
                <input
                  placeholder="Search..."
                  onChange={(e) => setSearch(e.target.value)}
                  type="text"
                  style={{
                    background: "none",
                    border: "1px solid gray",
                    borderRadius: "8px",
                    width: "100%",
                    padding: "8px",
                    color: "white",
                  }}
                />
                <div className="mt-3"> Favourite Tokens</div>
                <span
                  style={{ position: "absolute", top: "9px", right: "30px" }}
                >
                  <SearchOutlined />
                </span>
              </div>
              {tokenList.slice(0, 5).map((item, i) => (
                <div className="col-4 mb-2">
                  <div
                    key={i}
                    onClick={() => modifyToken(i)}
                    style={{
                      display: "flex",
                      gap: "5px",
                      border: "1px solid gray",
                      borderRadius: "12px",
                      padding: "6px",
                      cursor: "pointer",
                    }}
                  >
                    <div>
                      <img
                        src={item.img}
                        alt={item.ticker}
                        style={{ width: "20px" }}
                      />{" "}
                    </div>{" "}
                    <div>{item.ticker}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="tokenscroll-area  ">
            {filteredTokenList?.map((e, i) => {
              return (
                <>
                  <div
                    className="tokenChoice"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      modifyToken(i);
                      setCustomBalance(Number(e.bal) / 10 ** e.decimals);
                    }}
                  >
                    <div className="tokenChoice" key={i}>
                      <img src={e.img} alt={e.ticker} className="tokenLogo" />
                      <div className="tokenChoiceNames">
                        <div className="tokenName">{e.name}</div>
                        <div style={{ color: "white" }} className="tokenTicker">
                          {" "}
                          {e.bal == null
                            ? 0
                            : Number(e.bal) / 10 ** e.decimals}{" "}
                          &nbsp;{e.ticker}
                        </div>
                      </div>
                    </div>
                    <div style={{ marginRight: "10px" }}></div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </Modal>
      <ConfirmModal
        confirm={process}
        setConfirm={setProcess}
        setApprove={setApprove}
        isApprove={isApprove}
        tokenOne={tokenOne}
        tokenTwo={tokenTwo}
        tokenTwoAmount={tokenTwoAmount}
        tokenOneAmount={tokenOneAmount}
      />
      {SwapProcess ? <SwapWait /> : ""}
      <div className="swapBG">
        <div className="container d-flex justify-content-center mt-5">
          <div className="tradeBox">
            <div className="tradeBoxHeader">
              <h4 className="mb-0 ms-1">Swap</h4>
              {/* <div>
              <Popover
                content={settings}
                title="Settings"
                trigger="click"
                placement="bottomRight"
              >
                <SettingOutlined className="cog" />
              </Popover>
              </div> */}
            </div>

            {/*  */}
            <div className="swapBox">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <div className="assetOne" onClick={() => openModal(1)}>
                    <img
                      src={tokenOne?.img}
                      alt="assetOneLogo"
                      className="assetLogo"
                    />
                    {tokenOne?.ticker}
                    <DownOutlined />
                  </div>
                  <p className="tokenNameP">{tokenOne?.name}</p>
                </div>
                <div>
                  <div style={{ color: "#c4a979", marginBottom: "8px" }}>
                    Swap From
                  </div>
                  <Input
                    placeholder="0"
                    value={tokenOneAmount}
                    type="number"
                    min="0"
                    onChange={changeAmount("one")}
                  />

                  <button
                    className="rounded maxButton"
                    onClick={handleMaxClick} // Add onClick handler
                  >
                    max
                  </button>
                  {address && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        paddingRight: "10px",
                        color: "white",
                        alignItems: "center",
                      }}
                    >
                      Balance:{" "}
                      {tokenOne?.bal == null
                        ? 0
                        : (
                            Number(tokenOne?.bal) /
                            10 ** tokenOne?.decimals
                          )?.toFixed(4)}
                    </div>
                  )}
                </div>
              </div>

              <div className="switchBox">
                <div className="switchButton" onClick={switchTokens}>
                  {/* <ArrowDownOutlined className="switchArrow" /> */}
                  <img
                    style={{ height: "50px" }}
                    className="switchArrow"
                    src="../../assets/images/dropIcon.png"
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center mb-1">
                <div>
                  <div className="assetTwo" onClick={() => openModal(2)}>
                    <img
                      src={tokenTwo?.img}
                      alt="logo.."
                      className="assetLogo"
                    />
                    {tokenTwo?.ticker}
                    <DownOutlined />
                  </div>
                  <p className="tokenNameP">{tokenTwo?.name}</p>
                </div>

                <div>
                  <div style={{ color: "#c4a979", marginBottom: "8px" }}>
                    Swap To
                  </div>
                  <Input
                    placeholder="0"
                    value={tokenTwoAmount}
                    onChange={changeAmount("two")}
                  />
                </div>
              </div>
              {address && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    paddingRight: "10px",
                    color: "white",
                    alignItems: "center",
                    marginBottom: "6px",
                  }}
                >
                  Balance:{" "}
                  {tokenTwo.bal == null
                    ? 0
                    : (Number(tokenTwo.bal) / 10 ** tokenTwo.decimals)?.toFixed(
                        4
                      )}
                </div>
              )}
              {tokenOne == tokenTwo ? (
                <span className="blink_me">
                  Warning! (Your 1st and 2nd Token is Same)
                </span>
              ) : !loading ? (
                <div
                  className="swapButton"
                  disabled={
                    !tokenOneAmount || address == "undefined" || !prices
                  }
                  onClick={fetchDexSwap}
                >
                  Swap{" "}
                </div>
              ) : (
                <div
                  className="swapButton"
                  disabled={
                    !tokenOneAmount || address == "undefined" || !prices
                  }
                >
                  Swaping
                  <span>
                    <span className="typeWriter">
                      <span> . . . . .</span>
                    </span>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Swap;

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { ethers } from 'ethers'

function switchNetwork() {
  const provider = new ethers.providers.Web3Provider(window.ethereum as any)
  return new Promise((resolve, reject) => {
    provider
      .send("wallet_switchEthereumChain", [
        {
          chainId: `${"0x38"}`,
        },
      ])
      .then(() => {
        resolve(null);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.code == 4902) {
          provider
            .send("wallet_addEthereumChain", [
              {
                chainId: "0x38",
                rpcUrls: [
                  "https://endpoints.omniatech.io/v1/bsc/mainnet/public",
                ],
                chainName: "BNB Chain",
                nativeCurrency: {
                  name: "BNB",
                  symbol: "BNB",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://bscscan.com/"],
              },
            ])
            .then(() => {
              resolve(null);
            })
            .catch(() => {
              reject("User rejected to switch chain");
            });
        }
      });
  })
}

async function getAddress() {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum as any)
    const [targetAddress] = await provider.send('eth_requestAccounts', [
      {
        chainId: `${'0x38'}`,
      },
    ])
    return targetAddress
  } catch (error) {
    console.log(error, 'error while connecting')
    throw new Error('User rejected to connect wallet')
  }
}

// eslint-disable-next-line import/no-unused-modules
export { getAddress, switchNetwork }
